import React from "react";

interface Props {
  classname: string;
}

const Logo: React.FC<Props> = ({ classname }) => {
  return (
    <svg
      viewBox="0 0 166 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        className="pen line-sig-1"
        d="M2 63C4.83333 60 11.9 53.2 17.5 50C23.1 46.8 68.8333 16.6667 91 2"
      />
      <path
        className="pen line-sig-2"
        d="M90.9485 1.90039C90.212 1.98222 89.2738 3.46567 88.8503 3.93324C86.8961 6.09102 85.1045 8.31408 83.3834 10.6562C80.9205 14.0078 78.5168 17.4727 75.92 20.7188C75.0142 21.851 74.3961 23.2809 73.6693 24.5376C72.6778 26.2522 71.5527 27.8867 70.562 29.598C69.9442 30.6651 69.0302 31.6646 68.2387 32.611C67.341 33.6842 66.7031 34.8228 66.0171 36.0378C64.449 38.8146 62.8137 41.5352 61.5738 44.4741C60.625 46.7231 59.8206 49.0031 58.8585 51.2478C58.1341 52.9383 57.1084 54.5456 56.3175 56.2065C55.8811 57.1229 56.1904 58.4808 55.6641 59.2703"
      />
      <path
        className="pen line-sig-3"
        d="M56.9709 57.9635C56.9709 57.4636 57.3453 57.2054 57.4646 56.7583C57.7329 55.7522 58.1113 54.7972 58.3431 53.7671C58.8638 51.4527 59.5111 49.1759 59.9766 46.8482C60.1475 45.9935 60.5867 44.4986 59.9766 43.719C59.2564 42.7988 58.426 41.9224 57.2322 41.6571C56.2192 41.432 54.6485 41.4849 53.7691 42.0201C52.2236 42.9609 50.7245 43.97 49.1807 44.9097C48.0093 45.6227 46.8286 46.2358 45.7031 46.9861C45.337 47.2301 44.4253 47.7911 44.4253 48.2929"
      />
      <path
        className="pen line-sig-4"
        d="M44.0332 48.0316C48.0554 48.0316 52.1288 47.5941 55.9835 46.4634C59.0861 45.5533 62.1333 44.3969 65.2765 43.6392C67.7555 43.0416 70.3748 42.7303 72.7835 41.8604C73.3887 41.6419 73.9863 41.4001 74.584 41.1635C75.1043 40.9575 75.6362 40.592 76.1813 40.481C77.3631 40.2403 78.5556 39.8808 79.6952 39.4864C80.9574 39.0495 82.2357 38.6516 83.4995 38.2303"
      />
      <path
        className="pen line-sig-5"
        d="M82.7151 38.7531C81.4501 38.7531 80.6056 39.1231 79.5787 39.9002C78.0349 41.0685 76.1739 41.8722 74.7725 43.2253C73.4196 44.5316 72.6359 46.8133 73.0373 48.6196C73.3416 49.9888 75.8055 49.7522 76.8344 49.5272C80.1306 48.8061 82.8153 46.6548 85.5321 44.8226C87.8927 43.2305 90.1186 41.9646 92.7777 40.9674C94.4262 40.3492 96.0832 39.6986 97.7437 39.1451"
      />
      <path
        className="pen line-sig-6"
        d="M96.8289 40.3213C96.2775 40.3826 95.2444 42.0947 94.9412 42.5356C94.4588 43.2373 94.0335 43.9552 93.5618 44.6629C93.0267 45.4655 92.6484 46.4355 92.0589 47.1822C91.8538 47.4419 91.4545 48.3011 91.2095 48.4236"
      />
      <path
        className="pen line-sig-7"
        d="M91.8633 47.5089C94.7443 44.6278 98.0509 41.8667 101.665 40.0599"
      />
      <path
        className="pen line-sig-8"
        d="M101.664 40.0599C101.664 41.437 100.791 42.9247 100.198 44.1111C99.7606 44.9853 99.3385 45.8877 98.9199 46.7248"
      />
      <path
        className="pen line-sig-9"
        d="M98.9199 46.7248C99.8451 45.9024 101.029 45.4786 102.049 44.7645C103.132 44.0066 104.373 43.7014 105.512 43.0729C106.701 42.4169 108.18 41.8153 109.491 41.4466C110.25 41.2332 111.203 40.8889 111.988 40.844C112.654 40.806 113.25 40.3709 113.919 40.3213C114.813 40.2551 115.608 39.4065 116.431 39.4065"
      />
      <path
        className="pen line-sig-10"
        d="M116.431 39.4065C116.015 39.4065 115.861 38.9542 115.517 38.7821C115.121 38.5843 114.442 38.4917 114.006 38.4917C113.527 38.4917 113.048 38.4917 112.569 38.4917C111.825 38.4917 111.162 39.5482 110.71 40.0309C109.621 41.1957 108.637 42.7054 108.198 44.2418C107.944 45.1316 107.283 46.3318 108.002 47.0515C108.688 47.7374 110.432 47.8601 111.306 47.6105C112.613 47.237 113.467 46.4218 114.406 45.4833C115.069 44.8201 115.329 43.9861 115.88 43.2979C116.073 43.0566 115.903 42.4432 116.105 42.2815C116.211 42.1963 116.343 41.5435 116.431 41.3667"
      />
      <path
        className="pen line-sig-11"
        d="M116.432 41.3668C117.517 41.3668 118.457 41.0961 119.51 40.844C120.963 40.4961 122.471 40.5827 123.953 40.5827C124.982 40.5827 126.22 40.3413 127.162 39.9292C127.673 39.7059 128.526 39.1451 129.108 39.1451"
      />
      <path
        className="pen line-sig-12"
        d="M127.932 40.0599C127.932 40.427 127.521 40.6747 127.409 41.0401C127.251 41.5547 126.826 42.0765 126.56 42.5502C126.155 43.2685 125.449 44.4679 125.449 45.2873"
      />
      <path
        className="pen line-sig-13"
        d="M125.449 45.2872C126.31 45.2872 127.08 44.4576 127.743 43.9804C129.149 42.9681 131.238 42.7604 132.898 42.4412C133.799 42.2679 134.562 41.7011 135.446 41.5047C135.89 41.4061 136.433 41.2979 136.818 41.1053"
      />
      <path
        className="pen line-sig-14"
        d="M136.818 41.1053C136.769 41.5936 136.096 42.2394 135.831 42.6735C135.467 43.2682 135.072 43.8119 134.734 44.4305C134.508 44.8444 134.325 46.1417 133.943 46.3327"
      />
      <path
        className="pen line-sig-15"
        d="M133.943 46.3326C134.156 46.3089 134.442 46.0627 134.625 45.9406C135.078 45.6389 135.5 45.5672 135.976 45.3452C136.494 45.1036 137.047 45.1965 137.595 45.0911C138.332 44.9494 139.101 44.8903 139.824 44.7354C141.028 44.4773 142.232 44.2417 143.475 44.2417C144.494 44.2417 145.512 44.5448 146.503 44.7354C148.576 45.134 150.5 45.8099 152.631 45.8099C153.434 45.8099 154.192 45.2349 154.918 44.9677C155.46 44.7678 156.003 44.7893 156.42 44.3724"
      />
      <path
        className="pen line-sig-16"
        d="M164.523 46.7247C163.912 46.7247 164.915 47.6485 164.915 46.7247"
      />

      <defs>
        <clipPath id="sig-16">
          <path
            d="M162.894 45.8577C163.405 45.01 164.085 44.6706 164.936 44.8401C165.787 45.01 166.128 45.6883 165.958 46.8754C165.787 48.0631 165.276 48.6564 164.426 48.6564C163.575 48.6564 162.979 48.4019 162.639 47.8931C162.298 47.3843 162.383 46.706 162.894 45.8577Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-15">
          <path
            d="M132.262 46.8754C131.869 46.2262 132.342 45.2695 132.342 45.2695C132.774 44.725 133.926 43.6223 135.07 43.568C136.214 43.5136 138.886 42.549 140.079 42.0735C140.079 42.0735 141.111 41.6176 142.983 41.787C144.848 41.9563 145.188 42.1034 146.793 42.7965L146.812 42.8047C147.593 43.1421 149.365 43.568 152.428 43.568C153.96 43.568 155.296 43.0113 156.305 43.7588C156.86 44.1704 157.023 44.9251 157.023 45.6033C157.023 46.9604 155.917 47.8087 153.705 48.1476C151.492 48.4869 148.769 48.3175 145.536 47.6387C142.302 46.9604 140.09 46.8754 138.899 47.3843C137.707 47.8931 136.431 48.1476 135.07 48.1476C133.708 48.1476 132.774 47.7227 132.262 46.8754Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-14">
          <path
            d="M132.262 46.8754C131.869 46.2261 132.342 45.2695 132.342 45.2695C133.061 44.013 134.98 41.5198 136 40.5C137 39.5 138 38.5 139.154 39.2427C140.49 40.3042 140.079 42.0734 140.079 42.0734C139.483 43.5045 138.5 46 137.5 46.8754C136.935 47.3704 136 48 135.07 48.1475C133.708 48.1475 132.774 47.7226 132.262 46.8754Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-13">
          <path
            d="M124.109 44.9037C123.433 43.714 124.35 42.4654 124.636 42.0414C125 41.5 127.816 39.7507 130.5 39.2428C131.181 39.114 133.485 39.2514 135.07 38.9884C137.112 38.6495 138.489 38.7141 139.154 39.2428C140.49 40.3042 140 42.0414 139.5 42.5C139 42.9586 132.342 45.2695 132.342 45.2695C132.342 45.2695 130.565 45.9127 129.965 46.1122C128.944 46.4515 127.837 46.5365 126.646 46.3666C125.455 46.1971 124.47 45.5397 124.109 44.9037Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-12">
          <path
            d="M124.109 44.9037C123.433 43.7141 124.636 42.0415 124.636 42.0415C125.257 41.0277 127.004 39.2443 127.5 38.5001C128.5 37 130.384 37.498 130.682 38.1137C130.981 38.7294 131 39 131 40C131 41 130.24 44.254 129.202 45.2696C128.163 46.2852 127.065 46.4241 126.646 46.3666C125.455 46.1972 124.47 45.5398 124.109 44.9037Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-11">
          <path
            d="M124.635 42.0414L118.876 42.6301C116 42.5 115.4 41.7999 115 40.9999C114.6 40.1999 115.769 38.7851 116.403 38.1777C117.334 37.532 122.221 37.8012 125.114 37.4618C128.007 37.1229 130.159 37.0338 130.682 38.1136C130.98 38.7293 131 39.05 131 39.5C131 41 124.635 42.0414 124.635 42.0414Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-10">
          <path
            d="M106.129 47.3528C105.273 46.6343 105.777 45.7784 105.777 45.7784C105.777 45.7784 107.406 41.1487 109.606 39.0999C110.687 38.0938 111.551 37.1622 112.718 36.9531C115.218 36.5058 117 38.0001 117 39C117 39.4039 115.761 39.8038 115 40.0001C114.318 40.176 113.337 40.6347 112.837 41.1347C112.337 41.6347 110.936 42.9431 110.5 43.5001C110.064 44.0571 111.961 43.7313 112.837 43.2194C113.713 42.707 114.002 42.0379 114.002 42.0379C114.002 42.0379 114.609 39.8241 116 40.0001C117.391 40.1762 118.5 40.5001 118.876 42.6302C118.876 42.6302 118.834 44.688 117.967 45.349C115.074 47.5543 112.613 48.3893 110.564 48.402C109.549 48.4086 107.362 48.3888 106.129 47.3528Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-9">
          <path
            d="M98.5017 47.3049C97.9998 46.9869 97.8757 47.1684 97.4998 45.5C97.1239 43.8317 103.336 41.1194 103.336 41.1194C103.336 41.1194 105.199 40.2253 106.766 39.7201C108.333 39.2143 108.494 39.3266 109.606 39.0998C114.226 38.1575 115 37.4999 116 38C116.803 38.4013 117.203 38.8426 117 39.4999C116.649 40.6372 115.435 41.2229 114.001 42.0378C112.82 42.7092 112.228 43.211 111.016 43.8259C109.069 44.8139 105.777 45.7783 105.777 45.7783C105.777 45.7783 103.701 46.5996 102.794 46.9869C101.602 47.4957 99.7561 47.9328 98.5017 47.3049Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-8">
          <path
            d="M98.502 47.3048C97.8735 46.9899 97.6239 46.6987 97.2578 46.1442C97.0593 45.8435 97.609 43.8359 98.4998 42.5C99.5 41 100 40.1462 100.911 40.0538C102.608 39.8818 103.265 40.0874 103.336 41.1193C103.5 43.4999 102.5 45.5 101.5 46.5C100.5 47.5 99.7564 47.9328 98.502 47.3048Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-7">
          <path
            d="M89.6318 47.6386C89.1657 46.4882 89.8814 45.3505 90.3175 44.8242C91.0004 44.0001 98.2467 40.9764 98.2467 40.9764C98.2467 40.9764 99.4117 40.206 100.911 40.0538C102.608 39.8818 103.147 40.1024 103.336 41.1193C103.5 42 98.7617 44.9999 98.0003 45.4999C97.2388 45.9999 95.5551 47.3318 94.9925 47.8931C94.1414 48.7413 93.2056 49.2502 92.1845 49.4196C91.1629 49.5896 90.0974 48.7896 89.6318 47.6386Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-6">
          <path
            d="M90.3172 44.8243C90.3172 44.8243 89.1655 46.4882 89.6316 47.6387C90.0972 48.7897 91.1627 49.5896 92.1843 49.4196C93.2054 49.2502 94.0643 48.7278 94.9922 47.8931C95.9998 46.9869 98.5 43.5386 98.5 41.5C98.5 40.5 97.7128 39.3831 96.8425 39.0998C94.9998 38.5 90.3172 44.8243 90.3172 44.8243Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-5">
          <path
            d="M72.7842 46.3666C72.4436 47.5542 72.6989 48.5719 73.55 49.4196C74.4005 50.2679 75.6769 50.5223 77.379 50.1829C79.0806 49.844 81.3781 48.9957 84.2713 47.6387C86.3359 46.6703 90.3176 44.8243 90.3176 44.8243C90.3176 44.8243 96.6262 41.8738 97.5 41C98 40.5 97.913 39.5893 96.8429 39.0998C95.374 38.4276 89.8867 40.006 87.3345 41.0237C86.0577 41.5326 83.8471 42.5111 81.1759 43.8859C82.9608 42.4871 84.0038 40.8965 83.9997 40.2131C83.9966 39.6432 83.689 38.8632 82.995 38.6067C80.0002 37.5 74.0002 43.5 73.5002 44.5C73.0002 45.5 73.0304 45.5072 72.8984 45.9678L72.7842 46.3666Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-4">
          <path
            d="M60.834 41.0237L61.5523 40.9443L66.6577 40.5149L67.1519 40.4513L74.8263 39.4972C77.549 39.1583 81.7472 38.0083 82.995 38.6067C83.8246 39.0051 83.9967 39.6432 83.9997 40.2131C84.0038 40.8965 83 42.5 81 43C79 43.5 75 44 75 44L72 44.5C71.1416 44.6431 69.4657 44.925 66.913 45.6033C66.1563 45.8043 63.7221 46.3823 63.7221 46.3823L56.4469 47.6387L50.831 49.1652C47.0867 50.1829 44.8745 50.4373 44.1939 49.9285C43.5129 49.4196 43.1729 48.8263 43.1729 48.1475C43.1729 47.8475 45.1109 45.4016 48.0002 44C51.6471 42.2308 56.7587 41.4353 60.834 41.0237Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-3">
          <path
            d="M58.728 40.1017C60.0314 40.5484 60.834 41.0237 60.834 41.0237C63.5231 43.5735 62.5165 48.5422 61.7889 51.0097C61.5246 51.9063 61.297 52.4727 61.297 52.4727C60.1054 56.374 58.9143 58.4943 57.7232 58.8332C57.1274 59.0032 56.6583 58.8413 56.2713 58.6744C55.8654 58.4994 55.5958 58.1549 55.4258 57.5611C55.0853 56.374 55.17 54.4236 55.6811 51.7094C55.9358 50.3528 56.1911 48.9957 56.4469 47.6387C56.4646 46.7591 56 45.3 54 46.5C52 47.7 45 50 44 49.5C43.3061 49.0938 43.1729 48.8263 43.1729 48.1475C43.1723 47.4692 43.9382 46.4515 45.4703 45.0944C47.0014 43.7379 48.7883 42.5502 50.831 41.5325C52.8731 40.5149 56.1334 39.2117 58.728 40.1017Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-2">
          <path
            d="M76.3578 16.8536C78.6332 12.9931 80.4896 10.7002 80.4896 10.7002C83.2722 6.84137 89.3536 -0.688664 91.4187 0.0618291C92.2693 0.570672 92.6098 1.24947 92.4398 2.0972C92.2693 2.94544 89.3765 6.84674 83.7606 13.8006C78.1441 20.7549 73.8898 26.946 70.9971 32.3733C69.5833 35.0254 68.17 37.677 66.7568 40.3286L66.6575 40.5148L63.7219 46.3823C63.7219 46.3823 62.1351 49.7274 61.2968 52.4726C60.1052 56.3739 58.9141 58.4943 57.7231 58.8332C57.1273 59.0031 56.6581 58.8413 56.2711 58.6744C55.8652 58.4994 55.5956 58.1549 55.4256 57.5611C55.0851 56.3739 55.1698 54.4235 55.6809 51.7094C56 50.5 57 48 58 46.5C59 45 61.5521 40.9443 61.5521 40.9443C61.5521 40.9443 64.7328 36.1001 67.168 31.6101C70.5713 25.3345 73.8255 21.1493 76.3578 16.8536Z"
            fill="#363636"
          />
        </clipPath>
        <clipPath id="sig-1">
          <path
            d="M78.6552 7.94895C86.3133 2.18223 90.5676 -0.44696 91.4187 0.0618832C92.2692 0.570726 92.6098 1.24952 92.4398 2.09725C91.5916 3.37506 84.1196 8.36501 80.4896 10.7003C80.4896 10.7003 78.3958 11.7963 75.3367 13.8006C72.4046 15.7215 62.2327 22.9598 44.1937 34.6632C26.1543 46.3666 15.6035 53.3209 12.5403 55.5257C9.4765 57.7311 7.17958 59.7664 5.64796 61.6319C4.11583 63.4978 2.75473 64.261 1.56364 63.9216C0.37204 63.5828 -0.1385 62.8195 0.0320205 61.6319C0.20203 60.4447 2.07367 58.4094 5.64796 55.5257C9.22123 52.6426 20.3684 45.4339 39.0883 33.8999C57.8078 22.3665 70.9966 13.7162 78.6552 7.94895Z"
            fill="#363636"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
